import instantExportsAPIAXIOS from "../services/custom-axios";

const UserServices = (pages) => {
  return instantExportsAPIAXIOS.get(`/api/users?page=${pages}`);
};

const postCreateUser = (name, jobs) => {
  return instantExportsAPIAXIOS.post(`/api/users`, { name: name, jobs: jobs });
};

const putUpdateUser = (name, jobs) => {
  return instantExportsAPIAXIOS.put(`/api/users/2`, { name: name, jobs: jobs });
};

const deleteUser = (id) => {
  return instantExportsAPIAXIOS.delete(`/api/users/2${id}`);
};

const loginApp = (user_admin, password) => {
  return instantExportsAPIAXIOS.post(`/login_user_admin_tiktok`, {
    user_admin,
    password,
  });
};

const updatePassword = (password_old, password_new, id) => {
  return instantExportsAPIAXIOS.put(`/update_password_admin_tiktok/${id}`, {
    password_old,
    password_new,
  });
};

export {
  UserServices,
  postCreateUser,
  putUpdateUser,
  deleteUser,
  loginApp,
  updatePassword,
};
