import React, { useEffect, useContext, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { loginApp } from "../../services/UserServices";
import { UserContext } from "../../context/useContext";
import { toast } from "react-toastify";

const FormLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const { loginContext } = useContext(UserContext);

  // const handlePasswordChange = (e) => {
  //   const value = e.target.value;
  //   setPassword(value);
  //   if (value.length < 8 && value !== "") {
  //     setErrors((prev) => ({
  //       ...prev,
  //       password: "Password must be at least 8 characters long",
  //     }));
  //   } else {
  //     setErrors((prev) => ({ ...prev, password: "" }));
  //   }
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!errors.email && !errors.password && email && password) {
  //     setIsLoading(true);
  //     // Simulating API call
  //     setTimeout(() => {
  //       setIsLoading(false);
  //       alert("Login successful!");
  //     }, 2000);
  //   }
  // };

  const handleLogin = async () => {
    //

    const res = await loginApp(email, password);
    // const tokenAdmin = "363663366";

    if (!email || !password) {
      toast.error("Email/Password phải nhập đầy đủ");
    } else {
      setIsLoading(true);
      setTimeout(() => {
        if (res && res.status === "success-login") {
          loginContext(email, res.results.idToken);
          navigate("/dashboard");
          toast.success("Bạn đã đăng nhập quản trị");
        } else if (res && res.status === "error-admin") {
          toast.error("Tài khoản không hợp lệ");
          setIsLoading(false);
        } else if (res && res.status === "error-password") {
          toast.error("Tài khoản không đúng mật khẩu");
          setIsLoading(false);
        }
      }, 2000);

      // toast.success("Bạn đã đăng nhập quản trị");
      // loginContext("root", tokenAdmin);
      // navigate("/");
    }
    // if (!email || !password) {
    //   toast.error("Email/Password phải nhập đầy đủ");
    //   return;
    // } else if (email === "Admin2024" && password === "Tiktokshop2024$") {
    //   toast.success("Bạn đã đăng nhập quản trị");
    //   loginContext("root", tokenAdmin);
    //   navigate("/");
    // }
    // setLoadingAPI(true);
    // let res = await loginApp(email, password);
    // if (res && res.token) {
    //   loginContext(email, res.token);
    //   navigate("/");
    //   toast.success(res.data);
    // } else {
    //   if (res && res.status === 400) {
    //     toast.error(res.data.error);
    //   }
    // }
    // setLoadingAPI(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  });

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-2xl transition-all duration-300 transform hover:scale-105">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Login Manager
          </h2>
        </div>
        <div className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div className="mb-4">
              <label htmlFor="email-address" className="sr-only">
                User
              </label>
              <input
                id="email-address"
                name="text"
                type="text"
                autoComplete="email"
                required
                className={`appearance-none rounded-lg relative block w-full px-3 py-2 border  placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                placeholder="User"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                // aria-invalid={errors.email ? "true" : "false"}
                aria-describedby="email-error"
                list="email-suggestions"
              />
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                className={`appearance-none rounded-lg relative block w-full px-3 py-2 border ${
                  errors.password ? "border-red-500" : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm pr-10`}
                placeholder="Password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  if (event.length < 8 && event !== "") {
                    setErrors((prev) => ({
                      ...prev,
                      password: "Password must be at least 8 characters long",
                    }));
                  } else {
                    setErrors((prev) => ({ ...prev, password: "" }));
                  }
                }}
                aria-invalid={errors.password ? "true" : "false"}
                aria-describedby="password-error"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FaEyeSlash className="h-5 w-5 text-gray-400" />
                ) : (
                  <FaEye className="h-5 w-5 text-gray-400" />
                )}
              </button>
              {errors.password && (
                <p
                  className="mt-2 text-sm text-red-600"
                  id="password-error"
                  role="alert"
                >
                  {errors.password}
                </p>
              )}
            </div>
          </div>

          <div>
            <button
              onClick={() => handleLogin()}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
              disabled={isLoading}
            >
              {isLoading ? (
                <FiLoader className="animate-spin h-5 w-5 mr-3" />
              ) : null}
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormLogin;
