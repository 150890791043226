import { useEffect, useState } from "react";
import ModalAddNewSlideBanner from "./components/modalAddNewBanner";
import { deleteListBanner, getListBanner } from "../../services/SlideAds";
import "./components/styles.css";

export default function PageSlideImages() {
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [listAPI, setListAPI] = useState([]);

  const getAPISlideBanner = async () => {
    let res = await getListBanner();
    setListAPI(res);
  };

  const handleCloseShow = () => {
    setIsShowModalAddNew(false);
  };

  const deleteList = async (id) => {
    await deleteListBanner(id);
    getAPISlideBanner();
  };

  useEffect(() => {
    getAPISlideBanner();
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">Slide Banner</h3>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded"
          onClick={() => setIsShowModalAddNew(true)}
        >
          Thêm Slide
        </button>
      </div>
      <hr className="mb-4" />

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="border-b px-4 py-2 text-left">Tiêu đề</th>
              <th className="border-b px-4 py-2 text-center">Image</th>
              <th className="border-b px-4 py-2 text-center">Content</th>
              <th className="border-b px-4 py-2 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {listAPI.map((row) => (
              <tr key={row.id}>
                <td className="border-t px-4 py-2">{row.name_title}</td>
                <td className="border-t px-4 py-2 text-center">
                  <img
                    src={row.image_slide}
                    alt={row.name_title}
                    className="w-52 h-auto"
                  />
                </td>
                <td className="border-t px-4 py-2 text-center">
                  {row.content}
                </td>
                <td className="border-t px-4 py-2 text-center">
                  <button
                    className="bg-red-500 text-white py-1 px-3 rounded"
                    onClick={() => deleteList(row.id)}
                  >
                    Xoá
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ModalAddNewSlideBanner
        show={isShowModalAddNew}
        handleClose={handleCloseShow}
        handleUpdateTable={getAPISlideBanner}
      />
    </div>
  );
}
