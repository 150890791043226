import { useState } from "react";
import { toast } from "react-toastify";
import { postPriceMoney } from "../../../services/ProfileServices";

const ModalAddMoney = (props) => {
  const { show, handleClose, idGet, handleUpdateTable } = props;
  const [price, setPrice] = useState(0);

  const handleSaveUser = async () => {
    let res = await postPriceMoney(idGet, price, "add");
    if (res) {
      handleClose();
      setPrice(0);
      handleUpdateTable();
      toast.success(
        `A đã nạp cho khách thành công với số tiền ${Number(
          price
        ).toLocaleString("en-US")} VNĐ`
      );
    } else {
      toast.error("A user failed");
    }
  };

  const closeApp = () => {
    handleClose();
    setPrice(0);
  };

  return (
    <div
      className={`fixed inset-0 z-10 overflow-y-auto ${
        show ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div
          className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          aria-hidden="true"
        ></div>
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Bạn nạp số tiền
                </h3>
                <div className="mt-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Giá tiền nạp
                  </label>
                  <input
                    type="number"
                    className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                    value={price}
                    onChange={(event) => setPrice(event.target.value)}
                  />
                  <br />
                  <label className="block text-sm font-medium text-gray-700">
                    {Number(price).toLocaleString("en-US")} VNĐ
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => handleSaveUser()}
              disabled={price.length < 5}
            >
              Nạp tiền
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => closeApp()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddMoney;
