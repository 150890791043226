import React from "react";
import { updatePassword } from "../../services/UserServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../context/useContext";

export default function PageChangePasswordAdmin() {
  const navigate = useNavigate();

  const [passwordOld, setPasswordOld] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordConfirmNew, setPasswordConfirmNew] = useState("");
  const [showPassWord, setShowPassWord] = useState(false);
  const [loadingAPI, setLoadingAPI] = useState(false);

  const { logout } = useContext(UserContext);

  const handleChangePassword = async () => {
    const id = localStorage.getItem("token");

    const res = await updatePassword(passwordOld, passwordNew, id);
    if (res.status === "success-change-password") {
      logout();
      navigate("/");
      toast.success(
        "Bạn đã đổi mật khẩu thành công. Xin vui lòng đăng nhập lại để xác thực "
      );
    } else if (res.status === "error-password") {
      toast.error("Mật Khẩu cũ không đúng xin vui lòng thử lại");
    }
  };

  const handleGoBack = () => {
    navigate("/");
  };

  const isFormValid =
    passwordOld &&
    passwordNew &&
    passwordConfirmNew &&
    passwordNew === passwordConfirmNew;

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full sm:w-1/3">
        <div className="text-2xl font-bold mb-6 text-center">Đổi Mật Khẩu</div>
        <div className="flex flex-col space-y-4 w-full">
          <div className="relative">
            <input
              type={showPassWord ? "text" : "password"}
              placeholder="Mật khẩu cũ"
              value={passwordOld}
              onChange={(event) => setPasswordOld(event.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <i
              className={`absolute right-2 top-2 cursor-pointer ${
                showPassWord ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"
              }`}
              onClick={() => setShowPassWord(!showPassWord)}
            ></i>
          </div>
          <div className="relative">
            <input
              type={showPassWord ? "text" : "password"}
              placeholder="Mật khẩu mới"
              value={passwordNew}
              onChange={(event) => setPasswordNew(event.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <i
              className={`absolute right-2 top-2 cursor-pointer ${
                showPassWord ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"
              }`}
              onClick={() => setShowPassWord(!showPassWord)}
            ></i>
          </div>
          <div className="relative">
            <input
              type={showPassWord ? "text" : "password"}
              placeholder="Xác Nhận Mật Khẩu"
              value={passwordConfirmNew}
              onChange={(event) => setPasswordConfirmNew(event.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <i
              className={`absolute right-2 top-2 cursor-pointer ${
                showPassWord ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"
              }`}
              onClick={() => setShowPassWord(!showPassWord)}
            ></i>
          </div>
        </div>
        <button
          className={`mt-6 py-2 px-4 w-full bg-blue-500 text-white font-semibold rounded-md ${
            isFormValid ? "hover:bg-blue-600" : "opacity-50 cursor-not-allowed"
          }`}
          disabled={!isFormValid || loadingAPI}
          onClick={() => handleChangePassword()}
        >
          {loadingAPI && <i className="fas fa-spinner fa-spin"></i>}
          &nbsp; Đổi Mật Khẩu
        </button>
        <div className="mt-4 flex items-center justify-center cursor-pointer text-blue-500">
          <i className="fa-solid fa-angles-left"></i>
          &nbsp;
          <span onClick={() => handleGoBack()}>Go Back</span>
        </div>
      </div>
    </div>
  );
}
