import { useEffect, useState } from "react";
import ModalAddNewSlideBanner from "./components/modalAddNewBanner";
import {
  getListNotificationTiktok,
  deleteNotificationTiktok,
} from "../../services/NotificationServices";

export default function PageNotification() {
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [listAPI, setListAPI] = useState([]);

  const getAPISlideBanner = async () => {
    let res = await getListNotificationTiktok();
    setListAPI(res.results);
  };

  const handleCloseShow = () => {
    setIsShowModalAddNew(false);
  };

  const deleteList = async (id) => {
    await deleteNotificationTiktok(id);
    getAPISlideBanner();
  };

  useEffect(() => {
    getAPISlideBanner();
  }, []);

  return (
    <div className="container mx-auto">
      <div className="my-6">
        <h3 className="text-2xl font-semibold mb-4">Quản lý thông báo</h3>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          onClick={() => setIsShowModalAddNew(true)}
        >
          Thêm Thông Báo
        </button>
      </div>

      <hr className="my-4" />

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tiêu đề
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Nội dung
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Ngày Đăng
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {listAPI.map((row) => (
              <tr key={row.id}>
                <td className="px-6 py-4 whitespace-nowrap text-left">
                  {row.content_notification}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center">
                  {row.description_notification}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center">
                  {row.date_show}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center">
                  <button
                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                    onClick={() => deleteList(row.id)}
                  >
                    Xoá
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ModalAddNewSlideBanner
        show={isShowModalAddNew}
        handleClose={handleCloseShow}
        handleUpdateTable={getAPISlideBanner}
      />
    </div>
  );
}
