import "./App.css";
import RoutesLinkApi from "./routes/routes_link";
import { ToastContainer } from "react-toastify";
import { useEffect, useContext } from "react";
import { UserContext } from "./context/useContext";

function App() {
  const { user, loginContext } = useContext(UserContext);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      loginContext(email, token);
    }
  }, []);
  return (
    <div>
      <RoutesLinkApi />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
