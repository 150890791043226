import { deleteProduction } from "../../../services/PromotionServiceHomePage";
import { toast } from "react-toastify";

const ModalConfirmProduction = (props) => {
  const { show, handleClose, nameGetDelete, handleUpdateTable } = props;

  const confirmDelete = async () => {
    const res = await deleteProduction(nameGetDelete.id);
    if (res) {
      toast.success("Delete Success");
      handleUpdateTable();
      handleClose();
    } else {
      toast.error("Delete Failed");
      handleClose();
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg w-full max-w-md mx-4 shadow-lg">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-lg font-semibold">Bạn muốn xoá</h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                ✕
              </button>
            </div>

            <div className="p-4">
              <p className="text-gray-700">
                Bạn muốn xoá sản phẩm {nameGetDelete.name_product}?
              </p>
            </div>

            <div className="flex justify-end p-4 border-t">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600"
                onClick={handleClose}
              >
                Close
              </button>
              <button
                className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
                onClick={confirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalConfirmProduction;
