/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/scope */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  listUserInHomePagiationKeyword,
  deleteIdToken,
  postUserRegister,
} from "../../services/ProfileServices";
import ReactPaginate from "react-paginate";
import { FaSearch, FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const PageManagerProfileFix = () => {
  ///
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [nameAccounnt, setNameAccounnt] = useState("");
  const [totalProductPage, setTotalProductPage] = useState(0);
  const [isShowModalAddUser, setIsShowModalAddUser] = useState(false);
  const [dataGetEdit, setdataGetEdit] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  ///adđ
  const [showPassWord, setShowPassWord] = useState(false);
  ////textEdit
  const [userName, setUserName] = useState("");
  const [passWord, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const isFormValid =
    userName &&
    phoneNumber &&
    passWord &&
    confirmPassword &&
    passWord === confirmPassword;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      fetchData(1, "");
    }, 500);
  }, []);

  ///
  const fetchData = async (page, search) => {
    const res = await listUserInHomePagiationKeyword(page, search);
    setDataList(res.listPage);
    setCurrentPage(res.page);
    setTotalProductPage(res.totalPages);
  };

  const handlePageClick = (event) => {
    fetchData(event.selected + 1, nameAccounnt);
  };

  const handleEdit = (getId) => {
    navigate(`/profile_guest/${getId}`);
  };

  const appEventSearch = () => {
    fetchData(1, `${nameAccounnt}`);
  };

  // const appClearSearch = () => {
  //   setNameAccounnt("");
  //   fetchData(1, "");
  // };

  // const handleClose = () => {
  //   setShowDelete(false);
  //   setIsShowModalAddUser(false);
  // };

  const handleConfirmDelete = (getId) => {
    setdataGetEdit(getId);
    setOpen(true);
  };
  ///add edit
  const confirmDelete = async () => {
    const res = await deleteIdToken(dataGetEdit.id);
    if (res) {
      toast.success("Delete Success");
      setOpen(false);
      setLoading(true);
      setUserName("");
      setPhoneNumber("");
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        setLoading(false);
        fetchData(1, "");
      }, 2000);
    } else {
      toast.error("Delete Failed");
      setOpen(false);
    }
  };

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mendatory")
      .min(3, "Password must be at 3 char long"),
    confirmPwd: Yup.string()
      .required("Password is mendatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const { formState } = useForm(formOptions);
  const { errors } = formState;

  const postUserNew = async () => {
    let res = await postUserRegister(
      userName,
      passWord,
      phoneNumber,
      "null",
      "null"
    );
    if (res.status === "email-did-have-account") {
      toast.error("Add new user not found");
    } else {
      toast.success("Add new success");
      setIsShowModalAddUser(false);
    }
  };

  // const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  return (
    <div className="">
      {/* Sidebar */}
      <main className="flex-1 p-4 overflow-auto">
        <div className="container mx-auto p-6 bg-gradient-to-r from-blue-100 to-purple-100 rounded-lg shadow-lg">
          <div className="mb-4 flex flex-col md:flex-row md:items-center md:justify-between">
            <div className=" flex w-full">
              <div className="relative mb-4 md:mb-0 md:w-1/3">
                <input
                  type="text"
                  placeholder="Search..."
                  value={nameAccounnt}
                  onChange={(event) => setNameAccounnt(event.target.value)}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      appEventSearch();
                      ev.preventDefault();
                    }
                  }}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <FaSearch className="absolute left-3 top-3 text-gray-400" />
              </div>
              <button
                onClick={() => appEventSearch()}
                className="flex items-center rounded-md transition-colors bg-blue-500 text-white mx-2 px-2 hover:bg-blue-700 "
              >
                Tìm kiếm
              </button>
            </div>
            <button
              className="flex items-center rounded-md transition-colors bg-blue-500 text-white hover:bg-blue-700 px-5 block"
              onClick={() => setIsShowModalAddUser(true)}
            >
              Thêm nguời dùng
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full bg-white rounded-lg overflow-hidden shadow-md">
              <thead className="bg-gray-200">
                <tr>
                  <th
                    // key={column}
                    className="p-3 text-left cursor-pointer select-none"
                    // onClick={() => handleSort(column)}
                  >
                    <div className="flex items-center">Id</div>
                  </th>
                  <th
                    // key={column}
                    className="p-3 text-left cursor-pointer select-none"
                    // onClick={() => handleSort(column)}
                  >
                    <div className="flex items-center">Mã giới thiệu</div>
                  </th>
                  <th
                    // key={column}
                    className="p-3 text-left cursor-pointer select-none"
                    // onClick={() => handleSort(column)}
                  >
                    <div className="flex items-center">Coin</div>
                  </th>
                  <th
                    // key={column}
                    className="p-3 text-left cursor-pointer select-none"
                    // onClick={() => handleSort(column)}
                  >
                    <div className="flex items-center">Họ Và tên</div>
                  </th>
                  <th
                    // key={column}
                    className="p-3 text-left cursor-pointer select-none"
                    // onClick={() => handleSort(column)}
                  >
                    <div className="flex items-center">Địa Chỉ</div>
                  </th>
                  <th
                    // key={column}
                    className="p-3 text-left cursor-pointer select-none"
                    // onClick={() => handleSort(column)}
                  >
                    <div className="flex items-center">Cấp bậc</div>
                  </th>
                  <th
                    // key={column}
                    className="p-3 text-left cursor-pointer select-none"
                    // onClick={() => handleSort(column)}
                  >
                    <div className="flex items-center">Đơn Hàng Vip</div>
                  </th>
                  <th
                    // key={column}
                    className="p-3 text-left cursor-pointer select-none"
                    // onClick={() => handleSort(column)}
                  >
                    <div className="flex items-center">Thao tác</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td className="text-center py-4">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
                    </td>
                  </tr>
                ) : dataList.length === 0 ? (
                  <tr>
                    <td className="text-center py-4">No results found</td>
                  </tr>
                ) : (
                  dataList.map((row) => (
                    <tr
                      key={row.id}
                      className={`hover:bg-gray-100 transition-colors`}
                    >
                      <td className="p-3">{row.phone_number}</td>
                      <td className="p-3">{row.code_intro}</td>
                      <td className="p-3">
                        {Number(row.coin_user).toLocaleString("en-US")} VNĐ
                      </td>
                      <td className="p-3">{row.user_name}</td>
                      <td className="p-3">{row.address}</td>
                      <td className="p-3">{row.vip_change}</td>
                      <td className="p-3">{row.pending_send}</td>
                      <td className="p-3">
                        <button
                          onClick={() => {
                            handleEdit(row.id);
                          }}
                        >
                          <FaEdit />
                        </button>{" "}
                        <button
                          onClick={() => {
                            handleConfirmDelete(row);
                          }}
                        >
                          <AiFillDelete />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex flex-col md:flex-row items-center justify-between">
            <div className="mb-4 md:mb-0">
              <p>
                Showing {currentPage} to {totalProductPage} entries
              </p>
            </div>
            <ReactPaginate
              nextLabel="Sau"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={totalProductPage}
              previousLabel="Trước"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </main>
      {/* confirm delete */}
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-red-600"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Thông báo
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Thông báo bạn có chắc chẵn xoá tài khoản này không
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => {
                    confirmDelete();
                  }}
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Đồng Ý
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Từ Chối
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      {/* adduserer */}
      <Dialog
        open={isShowModalAddUser}
        onClose={() => setIsShowModalAddUser(false)}
        className="relative z-10"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Thêm tài khoản
                    </DialogTitle>
                    <form className="w-full">
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Tên Người dùng
                        </label>
                        <input
                          type="text"
                          className="appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                          value={userName}
                          onChange={(event) => setUserName(event.target.value)}
                        />

                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Số điện thoại
                          </label>
                          <input
                            type="number"
                            maxLength="10"
                            size="10"
                            className="appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            value={phoneNumber}
                            onChange={(event) =>
                              setPhoneNumber(event.target.value)
                            }
                          />
                        </div>

                        <div className="relative space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Mật khẩu
                          </label>
                          <input
                            name="password"
                            type={showPassWord ? "text" : "password"}
                            className={`appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${
                              errors.password ? "border-red-500" : ""
                            }`}
                            value={passWord}
                            onChange={(event) =>
                              setPassword(event.target.value)
                            }
                          />
                          <i
                            className={`absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer ${
                              showPassWord
                                ? "fa-solid fa-eye"
                                : "fa-solid fa-eye-slash"
                            }`}
                            onClick={() => setShowPassWord(!showPassWord)}
                          ></i>
                          {errors.password && (
                            <p className="mt-2 text-sm text-red-600">
                              {errors.password.message}
                            </p>
                          )}
                        </div>

                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Xác Nhận Mật Khẩu
                          </label>
                          <input
                            name="confirmPwd"
                            type={showPassWord ? "text" : "password"}
                            className={`appearance-none rounded-lg relative block w-full px-3 py-2 border placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${
                              errors.confirmPwd ? "border-red-500" : ""
                            }`}
                            value={confirmPassword}
                            onChange={(event) =>
                              setConfirmPassword(event.target.value)
                            }
                          />
                          {errors.confirmPwd && (
                            <p className="mt-2 text-sm text-red-600">
                              {errors.confirmPwd.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => {
                    postUserNew();
                  }}
                  disabled={!isFormValid} // Disable button if form is invalid
                  className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${
                    isFormValid
                      ? "bg-blue-600 hover:bg-blue-500"
                      : "bg-gray-300 cursor-not-allowed"
                  }`}
                >
                  Đồng ý
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsShowModalAddUser(false);
                    setUserName("");
                    setPhoneNumber("");
                    setPassword("");
                    setConfirmPassword("");
                  }}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Từ Chối
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PageManagerProfileFix;
