import React, { useState, useEffect } from "react";
import { getListPageWithdrawMoney } from "../../services/HistoryTransaction";
import ReactPaginate from "react-paginate";
import ModalConfirmPaymentGuestWithdraw from "./components/ModelConfirmPaymentGuestWithdraw";
import Sidebars from "../../components/sidebars";
import Navbars from "../../components/navbars";

const PageWithDrawMoney = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState("Home");
  const [isMobile, setIsMobile] = useState(false);

  const [listPriceWithDraw, setListPriceWithDraw] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [dataGetEdit, setdataGetEdit] = useState({});
  const [dataGetNameBank, setDataGetNameBank] = useState("");
  const [idBankName, setBankName] = useState("");
  const [textKeyFind, setTextKeyFind] = useState("");
  const [totalProductPage, setTotalProductPage] = useState(0);

  useEffect(() => {
    getAPIPrice(1, textKeyFind);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getAPIPrice = async (page, keySearch) => {
    let resPrice = await getListPageWithdrawMoney(page, keySearch);
    setListPriceWithDraw(resPrice.listPage);
    setTotalProductPage(resPrice.totalPages);
  };

  const getPriceIdWithDraw = async (row) => {
    window.location.href = `/id_withdraw/${row.id}`;
  };

  const handleCloseShow = () => {
    setIsShowModal(false);
  };

  const appEventSearch = () => {
    getAPIPrice(1, textKeyFind);
  };

  const handlePageClick = (event) => {
    getAPIPrice(event.selected + 1, textKeyFind);
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <main className="flex-1 p-4 overflow-auto">
        <div className="p-4">
          <div className="mb-4">
            <h3 className="text-xl font-semibold">
              Danh Sách Khách Hàng Rút Tiền
            </h3>
          </div>

          <input
            type="text"
            placeholder="Tìm kiếm sản phẩm"
            className="border border-gray-300 rounded p-2 mb-4 w-full"
            onChange={(e) => {
              setTextKeyFind(e.target.value);
            }}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                appEventSearch();
              }
            }}
          />

          <hr />

          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border px-4 py-2">Id Rút Tiền</th>
                  <th className="border px-4 py-2 text-right">Id User</th>
                  <th className="border px-4 py-2 text-right">Số điện thoại</th>
                  <th className="border px-4 py-2 text-right">Trạng thái</th>
                  <th className="border px-4 py-2 text-right">Ngày</th>
                  <th className="border px-4 py-2 text-right">Người dùng</th>
                  <th className="border px-4 py-2 text-right">Số tiền rút</th>
                  <th className="border px-4 py-2 text-right">Thực hiện</th>
                </tr>
              </thead>
              <tbody>
                {listPriceWithDraw.map((row) => (
                  <tr key={row.id} className="hover:bg-gray-50">
                    <td className="border px-4 py-2">{row.id}</td>
                    <td className="border px-4 py-2 text-right">
                      {row.id_user_money}
                    </td>
                    <td className="border px-4 py-2 text-right">
                      {row.phone_number}
                    </td>
                    <td className="border px-4 py-2 text-right">
                      {row.status}
                    </td>
                    <td className="border px-4 py-2 text-right">
                      {row.date_create_at}
                    </td>
                    <td className="border px-4 py-2 text-right">
                      {row.user_guest}
                    </td>
                    <td className="border px-4 py-2 text-right">
                      {Number(row.id_price_withdraw).toLocaleString("en-US")}{" "}
                      VNĐ
                    </td>
                    <td className="border px-4 py-2 text-right">
                      {row.status === "Loading Withdraw" && (
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
                          onClick={() => getPriceIdWithDraw(row)}
                        >
                          Rút tiền
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <ReactPaginate
            nextLabel="Sau"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={totalProductPage}
            previousLabel="Trước"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="flex justify-center space-x-2 mt-4"
            activeClassName="bg-blue-500 text-white"
          />

          <ModalConfirmPaymentGuestWithdraw
            show={isShowModal}
            handleClose={handleCloseShow}
            nameGetConfirm={dataGetEdit}
            idBankName={idBankName}
            idBank={dataGetNameBank}
            handleUpdateTable={getAPIPrice}
          />
        </div>
      </main>
    </div>
  );
};

export default PageWithDrawMoney;
