import { useState } from "react";
import { insertNotificaitonTiktok } from "../../../services/NotificationServices";
import { toast } from "react-toastify";
import "./styles.css";

const ModalAddNew = (props) => {
  const { show, handleClose, handleUpdateTable } = props;
  const [nameTitle, setNameTitle] = useState("");
  const [content, setContent] = useState("");

  const setClose = () => {
    setNameTitle("");
    setContent("");
    handleClose();
  };

  const postInProductInApp = async () => {
    let responseData = await insertNotificaitonTiktok(nameTitle, content);
    if (responseData) {
      setClose();
      handleUpdateTable();
      toast.success("A user success");
    } else {
      toast.error("A user failed");
    }
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
            <div className="flex justify-between items-center pb-3 border-b">
              <h3 className="text-lg font-semibold">Thêm thông báo</h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setClose()}
              >
                &times;
              </button>
            </div>
            <div className="mt-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Tên Tiêu Đề
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={nameTitle}
                  onChange={(event) => setNameTitle(event.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Nội Dung
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={content}
                  onChange={(event) => setContent(event.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-end pt-4 border-t">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-gray-600"
                onClick={() => setClose()}
              >
                Đóng
              </button>
              <button
                className={`px-4 py-2 rounded-md ${
                  nameTitle === "" || content === ""
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
                disabled={nameTitle === "" || content === ""}
                onClick={() => postInProductInApp()}
              >
                Thêm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalAddNew;
