import { useEffect, useState } from "react";
import ModalAddNew from "./components/modalAddNew";
import ModalEditUser from "./components/ModelEditUser";
import ModalConfirmProduction from "./components/ModelConfirm";
import ReactPaginate from "react-paginate";
import { listProductInHomePagiation } from "../../services/PromotionServiceHomePage";
import "./components/styles.css";

export default function PageManagerProductionInHomePages() {
  const [listPromotion, setListPromotion] = useState([]);
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [isShowModalEditProduct, setIsShowModalEditProduct] = useState(false);
  const [isShowModalDeleteId, setIsShowModalDeleteId] = useState(false);
  const [dataProductionEdit, setProductionEdit] = useState({});
  const [dataProductionDelete, setDataProductionDelete] = useState({});
  const [totalProductPage, setTotalProductPage] = useState(0);

  const handleCloseShow = () => {
    setIsShowModalAddNew(false);
    setIsShowModalEditProduct(false);
    setIsShowModalDeleteId(false);
  };

  const handleClickEdit = (product) => {
    setProductionEdit(product);
    setIsShowModalEditProduct(true);
  };

  const handleClickDelete = (product) => {
    setDataProductionDelete(product);
    setIsShowModalDeleteId(true);
  };

  const getListPromotion = async (indexPage) => {
    let res = await listProductInHomePagiation(indexPage);
    setListPromotion(res.listPage);
    setTotalProductPage(res.totalPages);
  };

  useEffect(() => {
    loadingData();
  }, []);

  const handlePageClick = (event) => {
    getListPromotion(event.selected + 1);
  };

  const loadingData = async () => {
    await getListPromotion(1);
  };

  return (
    <div className="container mx-auto">
      <div className="my-3 flex justify-between items-center">
        <span className="text-xl font-bold">Danh sách sản phẩm:</span>
        <button
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          onClick={() => setIsShowModalAddNew(true)}
        >
          Thêm sản phẩm trang chủ
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 px-4 py-2">Id</th>
              <th className="border border-gray-300 px-4 py-2">Product</th>
              <th className="border border-gray-300 px-4 py-2">Giá trị</th>
              <th className="border border-gray-300 px-4 py-2">Giảm giá</th>
              <th className="border border-gray-300 px-4 py-2">Danh Mục</th>
              <th className="border border-gray-300 px-4 py-2">Hình Ảnh</th>
              <th className="border border-gray-300 px-4 py-2">Đánh Giá</th>
              <th className="border border-gray-300 px-4 py-2">
                Số lượng người mua
              </th>
              <th className="border border-gray-300 px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {listPromotion &&
              listPromotion.length > 0 &&
              listPromotion.map((item, index) => {
                return (
                  <tr key={`user-${index}`}>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.id}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.name_product}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {Number(item.price).toLocaleString("en-US")} VNĐ
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {Number(item.commission_discount).toLocaleString("en-US")}{" "}
                      VNĐ
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.category}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <img
                        src={item.image}
                        alt="Product"
                        className="w-24 h-12 object-cover"
                      />
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.rating.rate}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {item.rating.count}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <button
                        className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 mr-2"
                        onClick={() => handleClickEdit(item)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                        onClick={() => handleClickDelete(item)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="mt-4">
        <ReactPaginate
          nextLabel="Sau"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={totalProductPage}
          previousLabel="Trước"
          containerClassName="flex justify-center space-x-2"
          pageClassName="px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-200"
          activeClassName="bg-blue-500 text-white"
          previousClassName="px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-200"
          nextClassName="px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-200"
        />
      </div>
      <ModalAddNew
        show={isShowModalAddNew}
        handleClose={handleCloseShow}
        handleUpdateTable={loadingData}
      />
      <ModalEditUser
        show={isShowModalEditProduct}
        handleClose={handleCloseShow}
        handleUpdateTable={loadingData}
        dataProductEdit={dataProductionEdit}
      />
      <ModalConfirmProduction
        show={isShowModalDeleteId}
        handleClose={handleCloseShow}
        nameGetDelete={dataProductionDelete}
        handleUpdateTable={loadingData}
      />
    </div>
  );
}
