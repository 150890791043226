import { putAPIChangeMoney } from "../../../services/HistoryTransaction";
import { toast } from "react-toastify";

const ModalConfirmPaymentGuestWithdraw = (props) => {
  const {
    show,
    handleClose,
    nameGetConfirm,
    idBank,
    idBankName,
    handleUpdateTable,
  } = props;

  const getAPIProfile = async () => {
    const res = await putAPIChangeMoney(
      nameGetConfirm.id,
      nameGetConfirm.id_price_withdraw,
      nameGetConfirm.id_user_money
    );
    if (res) {
      if (res.status === "not-withdraw") {
        toast.error("Số dư không đủ xin vui lòng thử lại");
        handleUpdateTable();
        handleClose();
      } else {
        toast.success("Số dư đã được rút tiền");
        handleUpdateTable();
        handleClose();
      }
    } else {
      toast.error("Delete Failed");
      handleClose();
    }
  };

  if (!show) return null; // Prevents rendering if modal is not shown

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full">
        <div className="flex justify-between items-center border-b p-4">
          <h5 className="text-lg font-semibold">Bạn muốn chuyển cho khách</h5>
          <button className="text-gray-600" onClick={handleClose}>
            &times; {/* Close icon */}
          </button>
        </div>
        <div className="p-4">
          <img
            src={`https://img.vietqr.io/image/${idBankName}-${idBank}-compact.png?amount=${nameGetConfirm.id_price_withdraw}&addInfo=Rut tien cho ban id ${nameGetConfirm.id_user_money}`}
            className="w-full h-auto mb-4"
            alt="QR Code"
          />
          <p>{`STK Của khách: ${idBankName} - ${idBank}`}</p>
          <p>
            Bạn muốn rút cho khách{" "}
            {Number(nameGetConfirm.id_price_withdraw).toLocaleString("en-US")}{" "}
            VNĐ
          </p>
        </div>
        <div className="flex justify-end p-4 border-t">
          <button
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400 transition duration-200"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
            onClick={getAPIProfile}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmPaymentGuestWithdraw;
