/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../context/useContext";
import { FaHome, FaInfoCircle, FaCog, FaEnvelope } from "react-icons/fa";
import {
  IoDiamondOutline,
  IoDocument,
  IoMegaphoneOutline,
  IoOptionsOutline,
  IoPeopleSharp,
  IoEnterOutline,
} from "react-icons/io5";

const Sidebars = ({ isSidebarOpen, isMobile, activePage, setActivePage }) => {
  const navItems = [
    {
      name: "Home",
      icon: <FaHome />,
      linkOpen: {
        pathname: `/dashboard`,
      },
    },
    {
      name: "Quản lý Nạp",
      icon: <IoDocument />,
      linkOpen: {
        pathname: `/manager_price`,
      },
    },
    {
      name: "Quản lý Rút",
      icon: <IoDocument />,
      linkOpen: {
        pathname: `/withdraw_manager`,
      },
    },
    {
      name: "Quản lý giao dịch đơn hàng",
      icon: <FaInfoCircle />,
      linkOpen: {
        pathname: `/manager_transaction`,
      },
    },
    {
      name: "Quản lý Sản Phẩm",
      icon: <IoDiamondOutline />,
      linkOpen: {
        pathname: `/product_promotion`,
      },
    },
    {
      name: "Quản lý Người dùng",
      icon: <IoPeopleSharp />,
      linkOpen: {
        pathname: `/manager_profile`,
      },
    },
    {
      name: "Quản lý Slide",
      icon: <IoDiamondOutline />,
      linkOpen: {
        pathname: `/manager_slide`,
      },
    },
    {
      name: "Quản lý Thông Báo",
      icon: <IoMegaphoneOutline />,
      linkOpen: {
        pathname: `/manager_notification_app`,
      },
    },
    {
      name: "Quản lý Sản Phẩm Trang Chủ",
      icon: <FaInfoCircle />,
      linkOpen: {
        pathname: `/product_promotion_list_home_page`,
      },
    },
    {
      name: "Quản lý Ưu đãi Slide",
      icon: <FaInfoCircle />,
      linkOpen: {
        pathname: `/manager_slide_endnow`,
      },
    },
  ];

  const { logout, user } = useContext(UserContext);
  const location = useLocation();
  // const [hideHeader, setHideHeader] = useState(false);

  // useEffect(() => {
  //   if (window.location.pathname === "/login") {
  //     setHideHeader(true);
  //   }
  // }, []);

  const navigate = useNavigate();
  const handleClickSignOut = () => {
    logout();
    navigate("/");
  };

  const NavLink = ({ item }) => (
    <a
      className={`flex items-center p-2 rounded-md transition-colors duration-200 ${
        item.linkOpen.pathname === location.pathname
          ? "bg-blue-600 text-white"
          : "text-gray-700 hover:bg-blue-100"
      }`}
      // onClick={() => setActivePage(item.name)}
      aria-label={item.name}
    >
      <Link
        to={item.linkOpen}
        state={{ some: "value" }}
        className="flex items-center p-0 space-x-3"
      >
        <span className="mr-2">{item.icon}</span>
        <span>{item.name}</span>
      </Link>
    </a>
  );

  return (
    <aside
      className={`bg-white w-64 min-h-screen p-4 shadow-lg transition-all duration-300 ease-in-out ${
        isMobile ? (isSidebarOpen ? "translate-x-0" : "-translate-x-full") : ""
      } ${isMobile ? "fixed inset-y-0 left-0 z-50" : ""}`}
    >
      <div class="flex flex-col-reverse divide-y divide-y-reverse">
        <nav className="space-y-4">
          {navItems.map((item) => (
            <NavLink key={item.name} item={item} />
          ))}
        </nav>
      </div>

      {isMobile ? (
        <div class="flex flex-col">
          <a
            href="/change_password_admin"
            className={`flex items-center p-2 rounded-md transition-colors duration-200`}
          >
            <span className="mr-2">
              <IoOptionsOutline />
            </span>
            <span>Cài đặt</span>
          </a>
          <buttton
            onClick={() => handleClickSignOut()}
            className={`flex items-center p-2 rounded-md transition-colors duration-200`}
          >
            <span className="mr-2">
              <IoEnterOutline />
            </span>
            <span>Đăng Xuất</span>
          </buttton>
        </div>
      ) : (
        <div></div>
      )}
    </aside>
  );
};

export default Sidebars;
