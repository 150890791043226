import { useEffect, useState } from "react";
import ModalAddNewSlideBanner from "./components/modalAddNewBanner";
import { deleteListBanner, getListBanner } from "../../services/SlideAdsEndNow";

export default function PageSlideImagesEndNow() {
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [listAPI, setListAPI] = useState([]);

  const getAPISlideBanner = async () => {
    let res = await getListBanner();
    setListAPI(res);
  };

  const handleCloseShow = () => {
    setIsShowModalAddNew(false);
  };

  const deleteList = async (id) => {
    await deleteListBanner(id);
    getAPISlideBanner();
  };

  useEffect(() => {
    getAPISlideBanner();
  }, []);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold">ƯU ĐÃI DANH SÁCH</h3>
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
          onClick={() => setIsShowModalAddNew(true)}
        >
          Thêm Slide
        </button>
      </div>
      <hr className="mb-4" />

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 text-left">Tiêu đề</th>
              <th className="py-2 px-4 text-center">Image</th>
              <th className="py-2 px-4 text-center">Content</th>
              <th className="py-2 px-4 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {listAPI.map((row) => (
              <tr key={row.id} className="border-t">
                <td className="py-2 px-4">{row.name_title}</td>
                <td className="py-2 px-4 text-center">
                  <img
                    src={row.image_slide}
                    alt="Slide"
                    className="w-48 h-auto object-cover mx-auto"
                  />
                </td>
                <td className="py-2 px-4 text-center">{row.content}</td>
                <td className="py-2 px-4 text-center">
                  <button
                    className="bg-red-600 text-white px-4 py-1 rounded-md hover:bg-red-700"
                    onClick={() => deleteList(row.id)}
                  >
                    Xoá
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ModalAddNewSlideBanner
        show={isShowModalAddNew}
        handleClose={handleCloseShow}
        handleUpdateTable={getAPISlideBanner}
      />
    </div>
  );
}
