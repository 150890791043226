import React, { useState, useEffect } from "react";

const FormHomePage = () => {
  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <main className="flex-1 p-4 overflow-auto">
        <h1 className="text-2xl font-bold mb-4">Welcome to fry manager</h1>
        <p>
          This is the main content area. The sidebar and navbar are fully
          functional and responsive.
        </p>
      </main>
    </div>
  );
};

export default FormHomePage;
