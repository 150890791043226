export default function ModalProductionCheck(props) {
  const { show, handleClose, idProduction } = props;

  return (
    <div className={`${show ? "fixed inset-0 z-10" : "hidden"}`}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="text-center sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {idProduction.name_product}
                </h3>
                <div className="mt-4">
                  <img
                    src={idProduction.image}
                    className="w-full h-56 rounded-lg mb-4"
                    alt="Product"
                  />
                  <p className="text-gray-700">
                    Tên sản phẩm: {idProduction.name_product}
                  </p>
                  <p className="text-gray-700">
                    Giá tiền:{" "}
                    {Number(idProduction.price).toLocaleString("en-US")} VNĐ
                  </p>
                  <p className="text-gray-700">
                    Giá tiền sau hoa hồng:{" "}
                    {Number(idProduction.commission_discount).toLocaleString(
                      "en-US"
                    )}{" "}
                    VNĐ
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleClose}
            >
              Đóng
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
