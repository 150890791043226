import React, { useState, useEffect } from "react";
import { FaArrowRight, FaStar } from "react-icons/fa";
import { useContext } from "react";
import { UserContext } from "../context/useContext";
import { useNavigate } from "react-router-dom";

import Sidebars from "../components/sidebars";
import Navbars from "../components/navbars";

const PrivateRouter = (props) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState("Home");
  const [isMobile, setIsMobile] = useState(false);

  const features = [
    {
      title: "Innovative Design",
      description: "Cutting-edge UI/UX for seamless interaction",
    },
    {
      title: "Robust Security",
      description: "State-of-the-art measures to protect your data",
    },
    {
      title: "24/7 Support",
      description: "Round-the-clock assistance for all your needs",
    },
  ];

  const testimonials = [
    {
      name: "John Doe",
      role: "CEO, TechCorp",
      content: "This product revolutionized our workflow!",
      image:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      name: "Jane Smith",
      role: "Designer, CreativeHub",
      content: "Intuitive and powerful. Highly recommended!",
      image:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    window.removeEventListener("resize", handleResize);
    const handleScroll = () => {
      const sections = ["home", "intro", "features", "testimonials", "contact"];
      const current = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (current) {
        // setActiveSection(current);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  //   if (formErrors[name]) {
  //     setFormErrors({ ...formErrors, [name]: "" });
  //   }
  // };

  // const validateForm = () => {
  //   const errors = {};
  //   if (!formData.name.trim()) errors.name = "Name is required";
  //   if (!formData.email.trim()) {
  //     errors.email = "Email is required";
  //   } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
  //     errors.email = "Invalid email format";
  //   }
  //   if (!formData.message.trim()) errors.message = "Message is required";
  //   if (!recaptchaValue) errors.recaptcha = "Please complete the reCAPTCHA";
  //   return errors;
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const errors = validateForm();
  //   if (Object.keys(errors).length === 0) {
  //     setIsSubmitting(true);
  //     // Simulating form submission
  //     setTimeout(() => {
  //       alert("Form submitted successfully!");
  //       setFormData({ name: "", email: "", message: "" });
  //       setIsSubmitting(false);
  //       setRecaptchaValue(null);
  //     }, 2000);
  //   } else {
  //     setFormErrors(errors);
  //   }
  // };

  const scrollToSection = () => {
    navigate("/login");
  };

  if (user && !user.auth) {
    return (
      <div className="font-sans bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen">
        <main className="pt-16">
          <section
            id="home"
            className="bg-gradient-to-r from-indigo-500 to-purple-600 text-white py-20"
          >
            <div className="container mx-auto px-6 text-center">
              <h2 className="text-4xl md:text-5xl font-bold mb-4 animate-fade-in-down">
                Welcome to Our Platform
              </h2>
              <p className="text-xl mb-8 animate-fade-in-up">
                Discover the power of innovation and simplicity
              </p>
              <button
                onClick={() => scrollToSection()}
                className="bg-white text-indigo-600 font-bold py-3 px-8 rounded-full hover:bg-indigo-100 transition-colors duration-300 flex items-center mx-auto"
                aria-label="Learn more about our platform"
              >
                Get Started <FaArrowRight className="ml-2" />
              </button>
            </div>
          </section>

          <section id="intro" className="py-20">
            <div className="container mx-auto px-6">
              <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
                Revolutionizing Your Experience
              </h2>
              <p className="text-xl text-center text-gray-600 mb-12 max-w-3xl mx-auto">
                Our platform combines cutting-edge technology with user-centric
                design to bring you an unparalleled digital experience. We're
                committed to innovation, security, and your success.
              </p>
              <div className="flex justify-center">
                <img
                  src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80"
                  alt="Team collaboration"
                  className="rounded-lg shadow-xl max-w-full h-auto align-middle border-none"
                />
              </div>
            </div>
          </section>

          <section id="features" className="bg-gray-100 py-20">
            <div className="container mx-auto px-6">
              <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
                Our Key Features
              </h2>
              <div className="flex flex-wrap -mx-4 justify-center">
                {features.map((feature, index) => (
                  <div key={index} className="w-full md:w-1/3 px-4 mb-8">
                    <div className="bg-white rounded-lg shadow-lg p-6 h-full transition-transform duration-300 hover:scale-105">
                      <div className="text-indigo-500 mb-4">
                        <FaStar className="text-4xl mx-auto" />
                      </div>
                      <h3 className="text-xl font-semibold mb-2 text-center">
                        {feature.title}
                      </h3>
                      <p className="text-gray-600 text-center">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section id="testimonials" className="py-20">
            <div className="container mx-auto px-6">
              <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
                What Our Clients Say
              </h2>
              <div className="flex flex-wrap -mx-4 justify-center">
                {testimonials.map((testimonial, index) => (
                  <div key={index} className="w-full md:w-1/2 px-4 mb-8">
                    <div className="bg-white rounded-lg shadow-lg p-6 h-full">
                      <div className="flex items-center mb-4">
                        <img
                          src={testimonial.image}
                          alt={testimonial.name}
                          className="w-12 h-12 rounded-full mr-4"
                        />
                        <div>
                          <h3 className="text-lg font-semibold">
                            {testimonial.name}
                          </h3>
                          <p className="text-gray-600">{testimonial.role}</p>
                        </div>
                      </div>
                      <p className="text-gray-600 italic">
                        "{testimonial.content}"
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </main>

        <footer className="bg-gray-800 text-white py-8">
          <div className="container mx-auto px-6 text-center">
            <p>&copy; 2024 WelcomePage. All rights reserved.</p>
            <div className="mt-4 flex justify-center space-x-4">
              <a href="#" className="hover:text-indigo-400 transition-colors">
                Privacy Policy
              </a>
              <a href="#" className="hover:text-indigo-400 transition-colors">
                Terms of Service
              </a>
              <a href="#" className="hover:text-indigo-400 transition-colors">
                Contact Us
              </a>
            </div>
          </div>
        </footer>
      </div>
    );
  }

  return (
    <>
      <div className="flex h-screen">
        {/* Sidebar */}
        <Sidebars
          isSidebarOpen={isSidebarOpen}
          isMobile={isMobile}
          activePage={activePage}
          setActivePage={setActivePage}
        />

        {/* Main content */}
        <div className="flex-1 flex flex-col">
          {/* Header */}
          <Navbars
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
            isMobile={isMobile}
            activePage={activePage}
            setActivePage={setActivePage}
          />
          {/* Page content */}
          <main className="flex-1 p-4 overflow-auto">{props.children}</main>
        </div>
      </div>
    </>
  );
};

export default PrivateRouter;
