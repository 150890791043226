import React, { useState, useEffect } from "react";
import { getPriceListPeople } from "../../services/HistoryTransaction";

const FormHomePage = () => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const [activePage, setActivePage] = useState("Home");
  // const [isMobile, setIsMobile] = useState(false);
  const [listPriceTransaction, setListPriceTransaction] = useState([]);

  useEffect(() => {
    getAPIPrice();
  }, []);

  const getAPIPrice = async () => {
    let resPrice = await getPriceListPeople();
    setListPriceTransaction(resPrice.results);
  };
  return (
    <main className="flex-1 p-4 overflow-auto">
      <div className="p-4 px-40">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Quản lý nạp tiền</h3>
          <a
            href="/withdraw_manager"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
          >
            Rút tiền
          </a>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border px-4 py-2">Id</th>
                <th className="border px-4 py-2 text-right">Ngày</th>
                <th className="border px-4 py-2 text-right">Tiền</th>
                <th className="border px-4 py-2 text-right">Nội Dung</th>
                <th className="border px-4 py-2 text-right">User</th>
              </tr>
            </thead>
            <tbody>
              {listPriceTransaction.map((row) => (
                <tr key={row.id} className="hover:bg-gray-50">
                  <td className="border px-4 py-2">{row.id}</td>
                  <td className="border px-4 py-2 text-right">
                    {row.time_createAt_map}
                  </td>
                  <td className="border px-4 py-2 text-right">
                    {row.coin_in_payment}
                  </td>
                  <td className="border px-4 py-2 text-right">{row.content}</td>
                  <td className="border px-4 py-2 text-right">
                    {row.user_name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};

export default FormHomePage;
