// import Sidebars from "../../components/sidebars";
// import Navbars from "../../components/navbars";
import React, { useState, useEffect, useRef } from "react";
import { FiX, FiAlertCircle } from "react-icons/fi";
import { CSSTransition } from "react-transition-group";

const ConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  errorMessage,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    setIsVisible(isOpen);
    if (isOpen) {
      document.body.style.overflow = "hidden";
      modalRef.current?.focus();
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      } else if (event.key === "Enter") {
        onConfirm();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, onConfirm]);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="modal"
      unmountOnExit
    >
      <div
        className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
        onClick={handleOutsideClick}
      >
        <div
          ref={modalRef}
          role="dialog"
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="w-full max-w-md p-6 bg-white rounded-lg shadow-xl"
          tabIndex={-1}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 id="modal-title" className="text-2xl font-bold text-gray-800">
              {title}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 focus:outline-none transition duration-150 ease-in-out"
              aria-label="Close"
            >
              <FiX size={24} />
            </button>
          </div>
          <div id="modal-description" className="mb-6">
            <p className="text-gray-600 text-lg">{description}</p>
            {errorMessage && (
              <div className="mt-4 p-3 bg-red-100 text-red-700 rounded-md flex items-center">
                <FiAlertCircle className="mr-2" />
                <span className="text-sm">{errorMessage}</span>
              </div>
            )}
          </div>
          <div className="flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 transition duration-150 ease-in-out"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ConfirmDialog;
