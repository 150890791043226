import { useEffect } from "react";
import { deleteProduction } from "../../../services/PromotionServices";
import { toast } from "react-toastify";

const ModalConfirmProduction = (props) => {
  const { show, handleClose, nameGetDelete, handleUpdateTable } = props;

  const confirmDelete = async () => {
    const res = await deleteProduction(nameGetDelete.id);
    if (res) {
      toast.success("Delete Success");
      handleUpdateTable();
      handleClose();
    } else {
      toast.error("Delete Failed");
      handleClose();
    }
  };

  useEffect(() => {
    // Prevent scrolling when the modal is open
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [show]);

  return (
    <>
      {show && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-96">
            <div className="flex justify-between items-center border-b p-4">
              <h5 className="text-lg font-bold">Bạn muốn xoá</h5>
              <button onClick={handleClose} className="text-gray-600">
                &times;
              </button>
            </div>
            <div className="p-4">
              <p>Bạn muốn xoá 1 sản phẩm {nameGetDelete.name_product}?</p>
            </div>
            <div className="flex justify-end border-t p-4">
              <button
                onClick={handleClose}
                className="mr-2 bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded"
              >
                Close
              </button>
              <button
                onClick={confirmDelete}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalConfirmProduction;
