import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "tailwindcss/tailwind.css";
import {
  getUserGuestId,
  updateUpdateBankAccount,
} from "../../services/ProfileServices";
import { toast } from "react-toastify";
import axios from "axios";

export default function PageUpdateBankAccount() {
  let { id } = useParams();

  const [listBankVietNam, setListBankVietNam] = useState([]);
  const [nameNumberBank, setNameNumberBank] = useState("");
  const [nameAccountBank, setNameAccountBank] = useState("");
  const [nameBankIntetional, setNameBankIntetional] = useState("");
  const [myJSONCheck, setMyJSONCheck] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [showAccount, setShowAccount] = useState(false);
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    getLogCheck();
  }, []);

  const getAPIGuest = async () => {
    const resAPI = await getUserGuestId(id);
    if (resAPI.status === "not-found-account") {
      setShowAccount(false);
    } else if (resAPI.status === "success") {
      setShowAccount(true);
      setCountryCode(resAPI.results.payment_bank.code_country);
      setMyJSONCheck(resAPI.results.payment_bank.more_back);
      setNameNumberBank(resAPI.results.payment_bank.id_bank);
      setNameAccountBank(resAPI.results.payment_bank.account_name);
      setNameBankIntetional(resAPI.results.payment_bank.more_back.name);
    }
  };

  const axiosShow = async () => {
    try {
      const response = await axios.get("https://api.vietqr.io/v2/banks");
      setListBankVietNam(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLogCheck = () => {
    axiosShow();
    getAPIGuest();
  };

  const onClickApp = (event) => {
    setMyJSONCheck(event);
    setShowList(false);
    setNameNumberBank("");
    setNameAccountBank("");
  };

  const sendUpdateAccount = async () => {
    const myJSONEdit = {
      code_country: "VN",
      account_name: nameAccountBank,
      id_bank: nameNumberBank,
      more_back: myJSONCheck,
    };
    const results = await updateUpdateBankAccount(id, myJSONEdit);
    if (results && results.status === "update-success") {
      toast.success("Bạn đã cập nhật ngân hàng thành công");
    } else {
      toast.error("Lỗi hệ thống");
    }
  };

  const sendAPIToChange = async () => {
    const myJSONEdit = {
      code_country: "US",
      account_name: nameAccountBank,
      id_bank: nameNumberBank,
      more_back: {
        id: 36,
        name: nameBankIntetional,
        code: "BANKInternational",
        bin: "5555555",
        shortName: nameBankIntetional,
        logo: "5555555",
        transferSupported: 1,
        lookupSupported: 1,
        short_name: nameBankIntetional,
        support: 3,
        isTransfer: 1,
        swiftCode: "XMMLMSCO",
      },
    };
    const results = await updateUpdateBankAccount(id, myJSONEdit);
    if (results && results.status === "update-success") {
      toast.success("Bạn đã cập nhật ngân hàng thành công");
      window.location.href = `/profile_guest/${id}`;
    } else {
      toast.error("Lỗi hệ thống");
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <main className="flex-1 p-4 overflow-auto">
        <div className="p-6 px-5  md:px-60">
          <h2 className="text-2xl font-bold">Chỉnh tài khoản khách hàng</h2>
          <h2 className="text-lg">ID Khách Hàng: {id}</h2>
          <div>
            {showAccount ? (
              <div>
                <h2 className="text-lg">Nguồn tài khoản: {countryCode}</h2>
                <div>
                  {countryCode === "VN" ? (
                    <div>
                      <div className="relative inline-block text-left">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          onClick={() => setShowList(true)}
                        >
                          Chọn ngân hàng
                        </button>
                        <div className="absolute z-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                          <div
                            className={`${
                              showList === false ? "hidden" : "block"
                            } py-1`}
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="dropdown-button"
                          >
                            {listBankVietNam.map((item) => (
                              <button
                                key={item.id}
                                onClick={() => onClickApp(item)}
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                <img
                                  className="h-8 w-8 rounded-full mr-2"
                                  src={item.logo}
                                  alt={item.name}
                                />
                                {item.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="flex flex-col">
                        {myJSONCheck && (
                          <div>
                            <img
                              className="h-16 w-16 rounded-full"
                              src={myJSONCheck.logo}
                              alt={myJSONCheck.name}
                            />
                            <label
                              htmlFor="inputPassword5"
                              className="mt-2 block text-gray-700"
                            >
                              Ngân Hàng bạn chọn: {myJSONCheck.name} -{" "}
                              {myJSONCheck.shortName}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label
                        htmlFor="inputPassword5"
                        className="block text-gray-700"
                      >
                        Ngân Hàng Quốc tế
                      </label>
                      <input
                        type="text"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        value={nameBankIntetional}
                        onChange={(e) => setNameBankIntetional(e.target.value)}
                        className="border rounded-lg p-2 mt-2 w-full"
                      />
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <div>
                    <br />
                    <label
                      htmlFor="inputAccountNumber"
                      className="block text-gray-700"
                    >
                      Số tài khoản
                    </label>
                    <input
                      type="text"
                      id="inputAccountNumber"
                      aria-describedby="passwordHelpBlock"
                      value={nameNumberBank}
                      onChange={(e) => setNameNumberBank(e.target.value)}
                      className="border rounded-lg p-2 mt-2 w-full"
                    />
                    <br />
                    <label
                      htmlFor="inputAccountName"
                      className="block text-gray-700"
                    >
                      Tên tài khoản
                    </label>
                    <input
                      type="text"
                      id="inputAccountName"
                      aria-describedby="passwordHelpBlock"
                      value={nameAccountBank}
                      onChange={(e) => setNameAccountBank(e.target.value)}
                      className="border rounded-lg p-2 mt-2 w-full"
                    />
                  </div>
                </div>

                {countryCode === "VN" ? (
                  <div>
                    <button
                      disabled={
                        nameNumberBank.length < 5 || nameAccountBank.length < 5
                      }
                      onClick={sendUpdateAccount}
                      className="bg-blue-500 text-white hover:bg-blue-600 rounded-md px-4 py-2"
                    >
                      Sửa lại Ngân Hàng
                    </button>
                  </div>
                ) : (
                  <button
                    disabled={
                      nameBankIntetional.length < 2 ||
                      nameNumberBank.length < 5 ||
                      nameAccountBank.length < 5
                    }
                    onClick={sendAPIToChange}
                    className="bg-blue-500 text-white hover:bg-blue-600 rounded-md px-4 py-2"
                  >
                    Sửa lại Ngân Hàng
                  </button>
                )}
              </div>
            ) : (
              <div>
                <h2 className="text-lg">Trống dữ liệu</h2>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
